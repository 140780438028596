import { IdskSelectOptions } from "@idsk/components-props";

export const region = "ap-south-1";
export const mandatorySignIn = true;
export const scope = ["email", "openid", "profile"];
export const responseType = "code";

export const localhost = "localhost";
export const devAppLink = "dev-campus.interviewdesk.in";
export const preProdAppLink = "pp-campus.interviewdesk.in";
export const prodAppLink = "campus.interviewdesk.in";

export const dev = "development";
export const preprod = "preprod";
export const prod = "prod";

type UserPoolId = {
  dev: IdskSelectOptions;
  preprod: IdskSelectOptions;
  prod: IdskSelectOptions;
};

export const domainToUserPoolId: UserPoolId = {
  dev: {
    "localhost:3006": "ap-south-1_DS6LBkKMM",
    "dev-campus.interviewdesk.in": "ap-south-1_aXurwNhJ6",
    "dev.gallopwork.com": "ap-south-1_DS6LBkKMM",
  },
  preprod: {
    "pp-campus.interviewdesk.in": "ap-south-1_75svIvtri",
    "pp.gallopwork.com": "ap-south-1_4V0UXl63J",
  },
  //Need to add for Production after First Deployment
  prod: {
    "campus.interviewdesk.in": "ap-south-1_kSUHIMw70",
    "gallopwork.com": "ap-south-1_zWkgwHIWd",
  },
};
export const domainToUserPoolWebClientId = {
  dev: {
    "localhost:3006": "69j8gmrgt3qtll7iop6veb1hkd",
    "dev-campus.interviewdesk.in": "52gb8m1e25ab6hd3t7th6prnqk",
    "dev.gallopwork.com": "69j8gmrgt3qtll7iop6veb1hkd",
  },
  preprod: {
    "pp-campus.interviewdesk.in": "4v13qcd6ebfaejhqfqo7mgh8ci",
    "pp.gallopwork.com": "2c3tnr4irdao9nl1qdn0amaj05",
  },
  // Need to add for Production after First deployment
  prod: {
    "campus.interviewdesk.in": "1g66o0uigcmhh2s6gu3r8sldd1",
    "gallopwork.com": "2mhn2h03hp2d9e9cm42bnqdu8s",
  },
};

export const redirectBaseUrls = {
  dev: {
    "localhost:3006":
      "https://idsk-coreinfracdkdevwave-dev-campus.auth.ap-south-1.amazoncognito.com/login?client_id=69j8gmrgt3qtll7iop6veb1hkd&response_type=code&scope=email+openid+profile&redirect_uri=",
    "dev-campus.interviewdesk.in":
      "https://idsk-coreinfracdkdevwave-dev-campus.auth.ap-south-1.amazoncognito.com/login?client_id=52gb8m1e25ab6hd3t7th6prnqk&response_type=code&scope=email+openid+profile&redirect_uri=",
    "dev.gallopwork.com":
      "https://3p-faceprep-coreinfracdkdevwave-dev-campus.auth.ap-south-1.amazoncognito.com/login?client_id=69j8gmrgt3qtll7iop6veb1hkd&response_type=code&scope=email+openid+profile&redirect_uri=",
  },
  preprod: {
    "pp-campus.interviewdesk.in":
      "https://idsk-coreinfracdkpreprodwave-preprod-campus.auth.ap-south-1.amazoncognito.com/login?client_id=4v13qcd6ebfaejhqfqo7mgh8ci&response_type=code&scope=email+openid+profile&redirect_uri=",
    "pp.gallopwork.com":
      "https://3p-faceprep-coreinfracdkpreprodwave-preprod-campus.auth.ap-south-1.amazoncognito.com/login?client_id=2c3tnr4irdao9nl1qdn0amaj05&response_type=code&scope=email+openid+profile&redirect_uri=",
  },
  // Need to add for Production after First deployment
  prod: {
    "campus.interviewdesk.in":
      "https://idsk-coreinfracdkprodwave-prod-campus.auth.ap-south-1.amazoncognito.com/login?client_id=1g66o0uigcmhh2s6gu3r8sldd1&response_type=code&scope=email+openid+profile&redirect_uri=",
    "gallopwork.com":
      "https://3p-faceprep-coreinfracdkprodwave-prod-campus.auth.ap-south-1.amazoncognito.com/login?client_id=2mhn2h03hp2d9e9cm42bnqdu8s&response_type=code&scope=email+openid+profile&redirect_uri=",
  },
};

export const unAuthCognitoIdentityPoolIds = {
  [dev]: "ap-south-1:3e3b081e-8861-4f73-97f0-806d69134a94",
  [preprod]: "ap-south-1:75ac10ea-dcc6-4612-8ca2-23016241654b",
  [prod]: "ap-south-1:af2eac06-d4b5-48ae-8e6e-beecefa80275",
};
